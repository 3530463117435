import React, { useEffect, useState } from 'react';
import * as style from './Acknowledgement.module.scss';

export default function Acknowledgement() {
  const [isAcknowlegementShown, setIsAcknowledgementShown] = useState(true);

  const hideAcknowlegement = () => {
    document.cookie = 'hasSeenWelcome=true';
    setIsAcknowledgementShown(false);
    document.body.classList.remove('noScroll');
  };

  useEffect(() => {
    if (document.cookie.includes('hasSeenWelcome=true')) {
      setIsAcknowledgementShown(false);
      document.body.classList.remove('noScroll');
    } else {
      document.cookie = 'hasSeenWelcome=true';
      document.body.classList.add('noScroll');
    }
    window.addEventListener('beforeunload', () => {
      document.cookie = 'hasSeenWelcome=false';
    });
  }, []);

  return (
    <>
      {isAcknowlegementShown && (
        <div className={style.container}>
          <div className={style.popup}>
            <h2 className={style.title}>Acknowledgement of country</h2>
            <p className={style.text}>
              I acknowledge the traditional custodians of the land on which I live and work, the Wurundjeri Woi Wurrung
              people of the Kulin Nation. I pay my deepest respects to Elders past, present and emerging, and
              acknowledge that sovereignty was never ceded.
            </p>
            <button type="button" onClick={hideAcknowlegement} className={style.button}>
              enter
            </button>
          </div>
        </div>
      )}
    </>
  );
}
