import React from 'react';
import * as style from './Hamburger.module.scss';

export default function Hamburger({ isOpen, handleClick }) {
  return (
    <button type="button" onClick={handleClick} className={[style.button, isOpen ? style.open : null].join(' ')}>
      <div className={style.lines}>
        <div className={[style.line, style.one].join(' ')} />
        <div className={[style.line, style.two].join(' ')} />
        <div className={[style.line, style.three].join(' ')} />
      </div>
    </button>
  );
}
