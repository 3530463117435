import React, { useState, useEffect, useCallback } from 'react';
import * as style from './PageContent.module.scss';

export default function PageContent({ children }) {
  const [viewHeight, setViewHeight] = useState(0);

  const calculateViewHeight = useCallback(() => {
    if (typeof window === 'undefined') return;
    setViewHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    calculateViewHeight();
    window.addEventListener('resize', calculateViewHeight);
    return () => window.removeEventListener('resize', calculateViewHeight);
  }, [calculateViewHeight]);

  return (
    <div
      className={style.padding}
      style={{
        minHeight: `calc(${viewHeight}px - var(--headerHeight) - var(--footerHeight) )`,
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
}
