import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PageContent from '../components/PageContent/PageContent';
import Acknowledgement from '../components/Acknowledgement/Acknowledgement';

export default function Layout({ children }) {
  useEffect(() => {
    window.scrollY = 0;
  });

  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      <Acknowledgement />
      <Header />
      <PageContent>{children}</PageContent>
      <Footer />
    </>
  );
}
