import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import * as style from './Menu.module.scss';

export default function Menu({ isOpen, closeMenu }) {
  const MenuLink = ({ to, children }) => (
    <li>
      <Link className={style.link} to={to} onClick={closeMenu}>
        {children}
      </Link>
    </li>
  );

  const getWindowHeight = () => {
    if (typeof window === 'undefined') return;
    return window.innerHeight;
  };

  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowHeight(getWindowHeight());
    window.addEventListener('resize', () => setWindowHeight(getWindowHeight()));
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        nodes {
          category_name
          slug {
            current
          }
        }
      }
    }
  `);

  const categories = data.allSanityCategory.nodes;
  return (
    <div className={[style.wrapper, isOpen ? style.open : null].join(' ')}>
      <div className={style.padding} style={{ height: `calc(${windowHeight}px - var(--headerHeight)` }}>
        <div className={style.links}>
          <ul className={style.galleryLinks}>
            {categories.map((category, i) => {
              const { category_name: name, slug } = category;
              return (
                <MenuLink to={`/folio/${slug.current}`} key={`menuLink${i}`}>
                  {name}
                </MenuLink>
              );
            })}
          </ul>
          <div className={style.otherLinks}>
            <ul>
              <MenuLink to="/about">About</MenuLink>
              <MenuLink to="/contact">Contact</MenuLink>
              <li>
                <a
                  href="https://www.instagram.com/ottonephotography/"
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                  onClick={closeMenu}
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className={style.fade} />
        </div>
      </div>
    </div>
  );
}
